.multiusable-section-carousel {
  max-width: 100% !important;
 
  .ant-card {
    .ant-card-body {
      display: none !important;
    }
  }

  .slick-track {
    gap: 20px !important;
    display: flex !important;
  }
}
